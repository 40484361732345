body {
  margin: 0;
  font-family: "IM Fell English", serif;
  letter-spacing: .01em;
}

.container {
  max-width: 768px;
}

footer {
  border-top: 1px solid #F3F3F3;
  background-color: #F9F9F9;
}

h2, h3 {
  color: #666;
}

#main {
  font-size: 1.2em;
}

#main blockquote {
  font-style: italic;
  font-size: 1.2em;
}
